import { render, staticRenderFns } from "./FormControl.vue?vue&type=template&id=2cce123c&scoped=true&"
import script from "./FormControl.vue?vue&type=script&lang=js&"
export * from "./FormControl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cce123c",
  null
  
)

export default component.exports