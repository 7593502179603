var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.fieldsets, function (fieldset) {
        return _c("div", { key: fieldset.id, staticClass: "mb-3" }, [
          _c("h6", [_vm._v(_vm._s(fieldset.legend))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "list-group list-group-flush" },
            _vm._l(_vm.getFieldsetColumns(fieldset.id), function (column) {
              return _c(
                "a",
                {
                  key: column.id,
                  staticClass: "list-group-item p-1 d-flex align-items-center",
                  on: {
                    click: function ($event) {
                      return _vm.toggleColumnVisibility(column.id)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "me-1 bi-check-lg",
                    class: {
                      invisible: _vm.activeColumns.indexOf(column.id) === -1,
                    },
                  }),
                  _vm._v(
                    "\n                " +
                      _vm._s(column.title) +
                      "\n            "
                  ),
                ]
              )
            }),
            0
          ),
        ])
      }),
      _vm._v(" "),
      _c("h6", [_vm._v(" ")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "list-group list-group-flush" },
        _vm._l(_vm.getFieldsetColumns(0), function (column) {
          return _c(
            "a",
            {
              key: column.id,
              staticClass: "list-group-item p-1 d-flex align-items-center",
              on: {
                click: function ($event) {
                  return _vm.toggleColumnVisibility(column.id)
                },
              },
            },
            [
              _c("i", {
                staticClass: "me-1 bi-check-lg",
                class: {
                  invisible: _vm.activeColumns.indexOf(column.id) === -1,
                },
              }),
              _vm._v("\n            " + _vm._s(column.title) + "\n        "),
            ]
          )
        }),
        0
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }