export default {
    namespaced: true,
    state() {
        return {
            listingName: undefined,
            
            tableName: undefined,
            componentId: undefined,
            
            // Listing service
            selectMode: false,
            showFilters: true,
            allItemsSelected: false,
            selectedIds: [],
            showArchive: false,
            onSelect: undefined,
            
            // Listing settings
            settings: {
                orderBy: '',
                orderDirection: 'Asc',
                pageSize: 20,
                viewMode: 'table',
                columns: [],
            },
            
            // Filters
            
            searchColumn: false,
            searchTerm: '',
            page: 1,
            
            // Only currently used by ODP's Course Dates component's School and Course menus, these allow for
            // listings to be filtered by relationshipOneToMany columns, and perhaps relationshipManyToMany.
            // It would be much better if this was supported by query params. E.g. ?schoolId=.
            categoryFilter: {},
            
            filters: {
                advancedFilter: {},
            },
            
            hiddenFilters: {},
            
            ids: [],
            allIds: [],
            
            // The total number of ids across all pages
            // Setting it to zero indicates that the current filters have no results.
            itemCount: false,
            
            allSelected: false,
            
            loadTimeout: undefined,
        }
    },
    mutations: {
        toggleShowArchive(state) {
            state.showArchive = !state.showArchive
        },
        // todo All settings should be managed with their own setters e.g. setPageSize
        setSettings(state, d) {
            Object.assign(state.settings, d)
        },
        setSetting(state, {name, value}) {
            state.settings[name] = value
        },
        setPage(state, n) {
            state.page = n
        },
        pageDown(state) {
            state.page--
        },
        pageUp(state) {
            state.page++
        },
        searchColumn(state, str) {
            state.searchColumn = str
        },
        searchTerm(state, str) {
            state.searchTerm = str
            state.page = 1
        },
        categoryFilter(state, obj) {
            state.categoryFilter = obj
        },
        setFilter(state, {id, value}) {
            state.filters.advancedFilter[id] = value
            this._vm.$set(state.filters.advancedFilter, id, value)
            
            // Always reset the pagination if a filter changes. This is so that if fewer pages exist, and the user is
            // viewing a page number higher, a blank page isn't displayed.
            state.page = 1
        },
        deleteFilter(state, name) {
            delete state.filters.advancedFilter[name]
        },
        setHiddenFilter(state, {key, value}) {
            state.hiddenFilters[key] = value
            this._vm.$set(state.hiddenFilters, key, value)
        },
        selectItem() {
        },
        selectAll(state) {
            state.selectedIds = []
            if (!state.allSelected) {
                state.ids.forEach((itemId) => {
                    state.selectedIds.push(itemId)
                })
            }
            state.allSelected = !state.allSelected
            state.allItemsSelected = false
        },
        selectNone(state) {
            state.selectedIds = []
            state.allSelected = false
            state.allItemsSelected = false
        },
        selectAllItems(state) {
            state.allItemsSelected = true
        },
        resetFilters(state) {
            state.searchTerm = ''
            state.searchColumn = false
            state.page = 1
            state.categoryFilter = {}
        },
        onShiftClick(state, id) {
            let len = state.selectedIds.length
            
            // Shift click only works if another item was previously selected.
            if (len) {
                let lastSelectedId = state.selectedIds[len - 1]
                let keys = []
                
                state.ids.forEach((itemId, key) => {
                    if (itemId === lastSelectedId) {
                        keys.push(key)
                    }
                    if (itemId === id) {
                        keys.push(key)
                    }
                })
                
                let start = keys.shift()
                let end = keys.pop()
                let ids = state.ids.slice(start, end + 1)
                
                ids.forEach((itemId) => {
                    if (state.selectedIds.indexOf(itemId) === -1) {
                        state.selectedIds.push(itemId)
                    }
                })
            }
        },
        onMetaClick(state, id) {
            let i = state.selectedIds.indexOf(id)
            if (i === -1) {
                state.selectedIds.push(id)
            } else {
                state.selectedIds.splice(i, 1)
                state.allSelected = false
            }
        },
    },
    getters: {
        selectedIdsLength(state) {
            return state.selectedIds.length
        },
        isFilterActive: (state) => (ignoreCategoryFilter) => {
            
            let advancedFilterIsSet = false
            for (let prop in state.filters.advancedFilter) {
                if (state.filters.advancedFilter.hasOwnProperty(prop)) {
                    let value = state.filters.advancedFilter[prop]
                    if (
                        value !== undefined
                        && value !== ''
                    ) {
                        advancedFilterIsSet = true
                    }
                }
            }
            
            return (
                state.searchTerm
                || advancedFilterIsSet
                || (
                    Object.keys(state.categoryFilter).length
                    && !ignoreCategoryFilter
                )
            )
        },
        // A method style getter is used so that it doesn't cache.
        getSearchParams: () => () => {
            let params = {}
            // With AngularJS the query params are after a hash so they cannot be retrieved using the following.
            //let params = (new URL(document.location)).searchParams;
            // We must instead glean them from the hash.
            let search = document.location.hash.split('?')[1]
            if (search) {
                // https://stackoverflow.com/questions/8648892/how-to-convert-url-parameters-to-a-javascript-object
                params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            }
            return params
        },
        visibleColumnIds(state, getters, rootState, rootGetters) {
            let data = []
            
            // The component's fields have duplicate display orders across the different fieldsets. E.g. name => 1,
            // createdDate => 1, so they must be ordered by fieldset.
            const allFields = rootGetters['componentStructure/get'](state.componentId)
            
            rootState.fieldsets.items
                // The fieldsets are returned ordered by display order, so collect the fields associated to each one.
                .filter(o => o.componentId === state.componentId)
                .forEach(o => {
                    const fieldsetId = o.id
                    const fields = allFields.filter(o => o.fieldsetId === fieldsetId && state.settings.columns.indexOf(o.id) > -1)
                    data.push(...fields)
                })
            
            // Now add the fields which aren't assigned to a fieldset e.g. Display Order
            const fields = allFields.filter(o => o.fieldsetId === 0 && state.settings.columns.indexOf(o.id) > -1)
            data.push(...fields)
            
            return data.map(a => a.id)
        }
    },
    actions: {
        init({rootState, state, commit, dispatch, rootGetters}, d) {
            state.listingName = d.listingName
            
            // These must default to the state's default above.
            state.selectedIds = d.selectedIds || []
            state.hiddenFilters = d.hiddenFilters || {}
            state.selectMode = d.selectMode || false
            state.onSelect = d.onSelect || undefined
            //state.showArchive = d.showArchive; // todo - The default is false and SelectListModal sets it as false,
            
            state.tableName = state.listingName.split('.')[0]
            state.componentId = rootState.components.componentIds[state.tableName]
            
            let fields
            dispatch('componentStructure/get', state.componentId, {root: true})
                .then((objs) => {
                    fields = objs
                    let settings = JSON.parse(JSON.stringify(state.settings))
                    let keys = Object.keys(state.settings)
                    keys.forEach((key) => {
                        const prefName = state.listingName + '__' + key
                        const val = rootGetters['user/pref'](prefName)
                        if (key === 'columns') {
                            let columns = []
                            if (!val) {
                                // Determine the default columns to display.
                                fields.forEach((field) => {
                                    if (field.browser) {
                                        columns.push(field.id)
                                    }
                                })
                            } else {
                                // Used below to double-check if a column ID exists.
                                let existingIds = []
                                fields.forEach((field) => {
                                    existingIds.push(field.id)
                                })
                                
                                val.forEach((id) => {
                                    if (existingIds.indexOf(id) > -1) {
                                        columns.push(id)
                                    }
                                })
                            }
                            settings[key] = columns
                        } else if (
                            key === 'pageSize'
                            && val
                        ) {
                            // Fix for erroneous
                            if (val === '0') {
                                dispatch('user/setPref', {
                                    name: prefName,
                                    value: 20
                                }, {root: true})
                                
                                settings[key] = 20
                                
                            } else {
                                settings[key] = parseInt(val)
                            }
                        } else if (val) {
                            settings[key] = val
                        }
                    })
                    
                    commit('setSettings', settings)
                })
            
            if (d.query) {
                const page = d.query.page
                if (page) {
                    state.page = page
                }
                
                const searchTerm = d.query.searchTerm
                if (searchTerm) {
                    state.searchTerm = searchTerm
                }
                
                const searchColumn = d.query.searchColumn
                if (searchColumn) {
                    state.searchColumn = searchColumn
                }
                
                let sort = d.query.sort
                if (sort) {
                    state.settings.orderBy = sort?.indexOf('-') === 0 ? sort.substr(1) : sort
                    state.settings.orderDirection = sort?.indexOf('-') === 0 ? 'Desc' : 'Asc'
                }
            }
            
            /* todo
            // Bug fix for when using history back to move out of an order details back to the order listing.
            // See: Jira:UI-325 - Without this, if the user pages to page 2, edits and order, and goes back
            // the listing was presenting page 1, even though the ?page=2 query string was in place.
            // todo - Remove once the bespoke order details is replaced by the generic form view.
            let params = _getSearchParams();
            let page = params['page'];
            if (
                page
                && state.page !== page
            ) {
                state.page = page;
            }
            */
        },
        scheduleLoad({state, dispatch}) {
            
            // This is required so that Vue doesn't show the cached version of a listing before it updates.
            // Without this, when in in product_options clicking in and out of different option's variables the previous
            // option's variables are presented for a split second.
            // todo - I imagine this hack wouldn't be need if the state and component were working in a proper Vue way.
            state.ids = []
            
            if (state.loadTimeout) {
                clearTimeout(state.loadTimeout)
            }
            
            // If using the pageSize input, and repeatedly clicking the up arrow, 250 reduces the number of requests.
            let delay = 250
            
            state.loadTimeout = setTimeout(() => {
                dispatch('load')
            }, delay)
        },
        load({state, dispatch, rootState}) {
            
            if (state.tableName) {
                if (
                    state.tableName === 'product_options'
                    || state.tableName === 'product_variables'
                ) {
                    rootState.navigationComponent = 'product_types'
                } else if (state.tableName.indexOf('product_variations__') === 0) {
                    rootState.navigationComponent = 'm_products_products'
                }
            }
            
            dispatch('modifiedItems/clearModifiedItems', {}, {root: true})
            
            dispatch('checkParams')
                .then(() => {
                    return dispatch('componentStructure/get', state.componentId, {root: true})
                })
                .then((objs) => {
                    
                    const searchParams = state.hiddenFilters
                    
                    // Franchise filtering
                    // Only implement franchiseId filtering if the user has a franchiseId. The franchiseId field in the
                    // Users component should be mandatory, so that all users of sites which have the field will filter.
                    if (rootState.user.user.franchiseId && state.tableName !== 'users') {
                        
                        // Franchise ID filter - Where franchiseId is a column of the current table
                        if (objs.find(o => o.columnName === 'franchiseId')) {
                            searchParams.franchiseId = rootState.user.user.franchiseId
                            dispatch('sendLoadViewRequest', {searchParams})
                            return
                        }
                        
                        const franchiseObjs = []
                        
                        // Used to prevent the number of times findFranchiseComponent is called recursively.
                        // Setting maxLevel to 2 supports ODP's Bookings.
                        // I.e. Bookings -> Course dates -> Courses
                        let level = 0
                        const maxLevel = 1
                        
                        // Recursively descends through components' relationshipOneToMany fields in search of a
                        // franchiseId column. E.g.
                        //   odp_course_bookings.courseDateId
                        //     -> odp_course_dates.courseId
                        //     -> odp_courses.franchiseId
                        // Whilst doing so, it retains the table and column names of components it searches. E.g.
                        //   const franchiseObjs = [
                        //     { tableName: 'odp_course_bookings', columnName: 'courseDateId' },
                        //     { tableName: 'odp_course_dates', columnName: 'courseId' },
                        //   ]
                        // These are then used for sourcing IDs in order to filter the current component. For example,
                        //   1. IDs from odp_courses are loaded where franchiseId = <user's franchiseId>.
                        //   2. The Courses IDs are then used to load IDs from odp_course_dates where
                        //      odp_course_dates.courseId = <odp_courses IDs>
                        //   3. The Course Dates IDs are then provided in the listing's searchParams to filter the
                        //      current listing. E.g. searchParams.courseDateId = <odp_course_dates IDs>
                        function findFranchiseComponent(componentId) {
                            const tableName = rootState.components.items.find(o => o.id === componentId).tableName
                            
                            dispatch('componentStructure/get', componentId, {root: true})
                                .then((objs) => {
                                    // Once a franchiseId column is found, stop the recursive process and apply the
                                    // filter.
                                    const franchiseIdColumn = objs.find(o => o.columnName === 'franchiseId')
                                    if (franchiseIdColumn) {
                                        applyFranchiseFilter(tableName, 'franchiseId', rootState.user.user.franchiseId)
                                        return
                                    }
                                    
                                    // Until a franchiseId column is found, or the number of recursions meets maxLevel,
                                    // search each component for a required relationshipOneToMany field and check the
                                    // fields target component for a franchiseId field.
                                    const item = objs.find(o => o.type === 'relationshipOneToMany' && o.required)
                                    if (item) {
                                        franchiseObjs.push({
                                            tableName,
                                            columnName: item.columnName
                                        })
                                        
                                        if (level <= maxLevel) {
                                            level++
                                            //console.log('level', level)
                                            findFranchiseComponent(item.categoryComponentId)
                                        } else {
                                            dispatch('sendLoadViewRequest', {searchParams})
                                        }
                                    } else {
                                        dispatch('sendLoadViewRequest', {searchParams})
                                    }
                                })
                        }
                        
                        // Initially called by findFranchiseComponent, once it locates a component with a franchiseId
                        // column, it loads IDs from tableName where franchiseId = the user's franchiseId.
                        // If franchiseObjs is populated it then recursively
                        function applyFranchiseFilter(tableName, columnName, ids) {
                            // If there are no more levels, then this is the currently loaded component e.g. Bookings.
                            // In which case, filter its results using the IDs e.g. searchParams.courseDateId => ids
                            if (franchiseObjs.length === 0) {
                                searchParams[columnName] = ids
                                dispatch('sendLoadViewRequest', {searchParams})
                                return
                            }
                            
                            dispatch('request/post', {
                                url: 'api/component/' + tableName,
                                postData: {
                                    field: 'id',
                                    [columnName]: ids,
                                    //isArchived: 0
                                },
                                customHeaders: {
                                    'X-Http-Method-Override': 'GET'
                                }
                            }, {root: true})
                                .then((o) => {
                                    const ids = o.data.values
                                    const obj = franchiseObjs.pop()
                                    applyFranchiseFilter(obj.tableName, obj.columnName, ids)
                                })
                        }
                        
                        findFranchiseComponent(state.componentId)
                        return
                    }
                    
                    dispatch('sendLoadViewRequest', {searchParams})
                })
        },
        sendLoadViewRequest({state, dispatch, rootState, rootGetters}, {searchParams}) {
            const postData = {
                field: 'id',
                //__itemCount: true
                __getAllIds: true
            }
            
            const component = rootState.components.items.find(o => o.tableName === state.tableName)
            
            // Only apply the filter if the feature is enabled.
            // Prevents isArchived: 0 generating errors on components without an isArchived column
            if (component.useArchive) {
                postData.isArchived = state.showArchive ? 1 : 0
            }
            
            const start = state.page - 1
            if (start) {
                postData.start = start * state.settings.pageSize
            }
            
            postData.limit = state.settings.pageSize
            
            if (
                state.settings.orderBy
                // Addresses issue when sort by column is deleted from a component
                && (
                    state.settings.orderBy === state.tableName + 'ID'
                    || rootGetters['componentStructure/get'](state.componentId).find(o => o.columnName === state.settings.orderBy)
                )
            ) {
                const sortPrefix = state.settings.orderDirection === 'Desc' ? '-' : ''
                postData.sort = sortPrefix + state.settings.orderBy
            }
            
            if (state.searchTerm) {
                postData.search = state.searchTerm
                
                if (state.searchColumn) {
                    postData.searchColumn = state.searchColumn
                }
            }
            
            const filters = {}
            Object.assign(filters, searchParams)
            Object.assign(filters, state.categoryFilter)
            
            if (Object.keys(filters).length) {
                postData.filters = filters
            }
            
            if (Object.keys(state.filters.advancedFilter).length) {
                postData.advancedFilters = state.filters.advancedFilter
            }
            
            dispatch('request/post', {
                url: 'api/component/' + state.tableName,
                postData: postData,
                customHeaders: {
                    'X-Http-Method-Override': 'GET'
                }
            }, {root: true})
                .then((obj) => {
                    let ids = obj.data.values
                    //let itemCount = obj.data.itemCount
                    state.allIds = obj.data.allIds
                    
                    dispatch('itemData/preload', {
                        tableName: state.tableName,
                        ids: ids,
                    }, {root: true})
                        .then((objs) => {
                            return dispatch('preloadViewItemData/get', {
                                tableName: state.tableName,
                                visibleColumns: state.settings.columns,
                                objs: objs,
                            }, {root: true})
                        })
                        .then((o) => {
                            state.ids = ids
                            //state.itemCount = itemCount
                            state.itemCount = state.allIds.length
                        })
                })
        },
        // When window.location is used to append params e.g. ?productId=123, this is required to preset the
        // categoryFilter params.
        checkParams({state, getters}) {
            
            // Listings presented in the SelectListModal with selectMode enabled should not use the query string params.
            if (state.selectMode) {
                return false
            }
            
            let params = getters.getSearchParams()
            let keys = Object.keys(params)
            if (keys.length) {
                keys.forEach((key) => {
                    if (
                        key !== 'page'
                        && key !== 'searchTerm'
                        && key !== 'searchColumn'
                    ) {
                        // todo - This needs to determine the field type from the name and set the filter settings
                        // accordingly. This will enable all listings to be filtered by the query string.
                        // Namespaced listings, e.g. <name>.selectList, could also be filtered by using complex query
                        // params e.g. ?productId=7&products.selectList={productId:3} (this isn't valid but you get my
                        // drift.
                        state.categoryFilter[key] = params[key]
                    }
                })
            }
        },
        setSettings({state, commit, dispatch}, d) {
            commit('setSettings', d)
            
            for (let prop in d) {
                if (d.hasOwnProperty(prop)) {
                    dispatch('user/setPref', {
                        name: state.listingName + '__' + prop,
                        value: d[prop]
                    }, {root: true})
                }
            }
        },
        setSetting({state, commit, dispatch}, {name, value}) {
            commit('setSetting', {name, value})
            
            dispatch('user/setPref', {
                name: state.listingName + '__' + name,
                value: value
            }, {root: true})
        },
        resetFilters({commit, dispatch}) {
            commit('resetFilters')
            
            dispatch('resetAdvancedFilters', {
                refresh: false
            })
        },
        resetAdvancedFilters({state, dispatch}, {refresh}) {
            dispatch('advancedFilters/get', {
                tableName: state.tableName,
                refresh: refresh,
            }, {root: true})
                .then((objs) => {
                    let advancedFilter = {}
                    objs.forEach((obj) => {
                        advancedFilter[obj.id] = undefined
                    })
                    state.filters.advancedFilter = advancedFilter
                })
        },
        onClick({state, commit}, id) {
            // Clicking rows inside the SelectListModal are treated the same as meta clicking.
            if (state.onSelect) {
                commit('onMetaClick', id)
                state.onSelect(state.selectedIds)
            } else {
                let i = state.selectedIds.indexOf(id)
                if (
                    i === -1
                    || state.allSelected
                    || state.selectedIds.length > 1
                ) {
                    state.selectedIds = [id]
                } else {
                    state.selectedIds = []
                }
                
                state.allSelected = false
            }
        },
        onDblClick({state, rootGetters}) {
            if (
                !state.onSelect
                && !state.selectMode
                && state.tableName.indexOf('product_variations__') !== 0
            ) {
                return rootGetters['user/access'](state.tableName, 'edit')
            }
        }
    }
}