<script>
import FormControlTypeText from '../form-control-type/FormControlTypeText'

export default {
    name: "FormControlMRegistrantsRegistrantsUsername",
    extends: FormControlTypeText,
    props: {
        currentData: Object,
        field: Object,
    },
    computed: {
        isHidden() {
            return !!this.$store.state.settings.config.hide_customers_username_field
        }
    },
    created() {
        if (this.isHidden) {
            this.field.hidden = true
        }
    }
}
</script>

<style scoped>

</style>